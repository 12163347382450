import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/Calendar/Calendar.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // DASHBOARD //
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/Dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // STUDENTS //
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/pages/Contacts/Contacts.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact/new',
    name: 'contact-new',
    component: () => import('@/views/pages/Contacts/ContactNew.vue'),
    meta: {
      requiresAuth: true,
      requiresStore: ['getUserlist'],
    },
  },
  {
    path: '/student/edit/:id',
    name: 'student-edit',
    component: () => import('@/views/pages/Contacts/StudentEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact/view/:id',
    name: 'contact-single',
    component: () => import('@/views/pages/Contacts/ContactSingle.vue'),
    meta: {
      requiresAuth: true,
      requiresStore: ['getInfos'],
    },
  },

  // END STUDENTS //
  // CREIPEF //
  {
    path: '/student/creipef/:id',
    name: 'student-creipef',
    component: () => import('@/views/pages/Creipef/CreipefForm.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END CREIPEF //

  // FORMATIONS //
  {
    path: '/formations',
    name: 'formations',
    component: () => import('@/views/pages/Formations/Formations.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/formation/edit/:id',
    name: 'formation-edit',
    component: () => import('@/views/pages/Formations/FormationEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/course/create',
    name: 'course-create',
    /* eslint-disable-next-line import/no-unresolved  */
    component: () => import('@/views/pages/Formations/CourseCreate.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END FORMATIONS //
  // CERTIFICATIONS //
  {
    path: '/certifications',
    name: 'certifications',
    component: () => import('@/views/pages/Certifications/Certifications.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/certification/edit/:id',
    name: 'certification-edit',
    component: () => import('@/views/pages/Certifications/CertificationEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/certification/create',
    name: 'certification-create',
    component: () => import('@/views/pages/Certifications/CertificationCreate.vue'),
    meta: {
      requiresAuth: true,
      requiresStore: ['getUserlist'],
    },
  },

  // END CERTIFICATIONS //

  // BUSINESS CONTACT //
  {
    path: '/business-contact/',
    name: 'business-contact',
    component: () => import('@/views/pages/BusinessContacts/BusinessContact.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/business-contact/new',
    name: 'business-contact-new',
    component: () => import('@/views/pages/BusinessContacts/BusinessContactNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/business-contact/:id',
    name: 'business-contact-single',
    component: () => import('@/views/pages/BusinessContacts/BusinessContactSingle.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END BUSINESS CONTACT //
  // REVIEWS //
  {
    path: '/public/satisfaction-instant/',
    name: 'reviews-hot',
    component: () => import('@/views/pages/Reviews/ReviewFormHot.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reviews/',
    name: 'reviews',
    component: () => import('@/views/pages/Reviews/Reviews.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END REVIEWS //
  // TUTORAT //
  {
    path: '/tutorat/',
    name: 'tutorat',
    component: () => import('@/views/pages/Tutorat/TutoratDatatable.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tutorat-planning/',
    name: 'tutorat-planning',
    component: () => import('@/views/pages/Tutorat/TutoratPlanning.vue'),
    meta: {
      requiresTuteur: true,
      requiresStore: ['getUserlist'],
    },
  },

  // END TUTORAT //
  // FICHIERS //
  {
    path: '/fichiers/',
    name: 'fichiers',
    component: () => import('@/views/pages/Fichiers/Fichiers.vue'),
    meta: {
      requiresAuth: true,
      requiresStore: ['getUserlist'],
    },
  },

  // END FICHIERS //
  // TEMPLATES GENERATOR //
  {
    path: '/templates/new',
    name: 'templates-new',
    component: () => import('@/views/pages/Templates/TemplateCreate.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/templates/convention/:id',
    name: 'templates-convention-pdf',
    component: () => import('@/views/pages/Templates/TemplateConventionPdf.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/templates/convention-generate',
    name: 'templates-convention-generator',
    component: () => import('@/views/pages/Templates/ConventionGenerator.vue'),
    meta: {
      requiresAuth: true,
      requiresStore: ['getCompanyInfos'],
    },
  },

  // END FICHIERS //

  // TEXTES //
  {
    path: '/textes/',
    name: 'textes',
    component: () => import('@/views/pages/Textes/Textes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/textes-manager',
    name: 'textes-manager',
    component: () => import('@/views/pages/Textes/TextesManager.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // MESSAGES //
  {
    path: '/messages/',
    name: 'messages',
    component: () => import('@/views/pages/Messages/Messages.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END TEXTES //

  // ADMIN //
  {
    path: '/stats/',
    name: 'stats',
    component: () => import('@/views/Administration/Statistiques/Statistiques.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/facturation',
    name: 'facturation',
    component: () => import('@/views/Administration/Facturation/Invoices.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/invoice/single/:id',
    name: 'invoice-single',
    component: () => import('@/views/Administration/Facturation/InvoiceSingle.vue'),

    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/new-invoice',
    name: 'invoice-new',
    component: () => import('@/views/Administration/Facturation/InvoiceNew.vue'),

    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/invoice-create',
    name: 'invoice-create',
    component: () => import('@/views/Administration/Facturation/InvoiceCreate.vue'),

    meta: {
      requiresAuth: true,
      requiresStore: ['getCompanyInfos'],

    },
  },
  {
    path: '/invoice/pdf/:id',
    name: 'invoice-pdf',
    component: () => import('@/views/Administration/Facturation/InvoicePdf.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Administration/Users/Users.vue'),
    meta: {
      requiresAdmin: true,
      requiresStore: ['getUserlist'],

    },
  },
  {
    path: '/user-new',
    name: 'user-new',
    component: () => import('@/views/Administration/Users/UserNew.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Administration/Settings/Settings.vue'),
    meta: {
      requiresAdmin: true,
      requiresStore: ['getCompanyInfos'],
    },
  },
  {
    path: '/debug',
    name: 'debug',
    component: () => import('@/views/pages/debug.vue'),
    meta: {
      requiresAdmin: true,
    },
  },

  // END ADMIN //
  // NOT USED //
  {
    path: '/livraisons',
    name: 'livraisons',
    component: () => import('@/views/Livraisons/Livraisons.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/livraison/new',
    name: 'NewLivraison',
    component: () => import('@/views/Livraisons/NewLivraison.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/account-settings',
    name: 'user-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // USER AUTH
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      requiresParamsToken: true,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/VerifyEmail.vue'),
    meta: {
      layout: 'blank',
      requiresParamsToken: true,
    },
  },
  {
    path: '/first-login',
    name: 'first-login',
    component: () => import('@/views/pages/FirstLogin.vue'),
    meta: {
      layout: 'blank',
      requiresParamsToken: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/pages/Onboarding.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // END USER AUTH

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-auth',
    name: 'error-auth',
    component: () => import('@/views/ErrorAuth.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    document.getElementById('app').scrollIntoView()
  },
})

router.beforeEach(async (to, from, next) => {
  // AUTHENTICATION SPECIFIC ROUTES
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch('getStatus')

    if (!store.getters.isLoggedIn || store.state.status === 'error') {
      next('/login')
    }
  }

  // QUICK CHECK FOR TUTEUR
  if (to.name === 'dashboard') {
    if (store.state.user.status !== 'tuteur' && store.state.user.status !== 'telepro') {
      next('/calendar')
    }
  }

  // POPULATE STORE BEFORE LOAD FOR REQUIRED PAGES
  if (to.matched.some(record => record.meta.requiresStore)) {
    try {
      const requirements = to.meta.requiresStore
      requirements.forEach(req => {
        store.dispatch(req)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // ADMIN ONLY ROUTES
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (!store.getters.isAdmin) next('/error-auth')

    else next()
  }

  // TUTEUR ONLY ROUTES
  if (to.matched.some(record => record.meta.requiresTuteur)) {
    if (!store.getters.isLoggedIn || !store.getters.isTuteur) {
      next('/error-auth')
    }
  }

  // PARAMS REQURIED ROUTES
  if (to.matched.some(record => record.meta.requiresParamsToken)) {
    if (!to.query.token) {
      next('/error-auth')
    }
  }

  // ALL IF GOOD
  next()
})

export default router
