<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <component
      :is="resolveLayout"
      :key="this.$route.path"
    >
      <router-view>
      </router-view>
    </component>
  </transition>
</template>

<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  metaInfo: {
    title: 'La Formation Pro',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    LayoutBlank,
    LayoutContent,
  },
  computed: {
    resolveLayout() {
      // Handles initial route
      if (this.$route.name === null) return null

      if (this.$route.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    },
  },
  head() {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' },
      ],
    }
  },
}
</script>

<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
