<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          text
          v-bind="attrs"
          v-on="on"
        >
          A Faire
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          tile
        >
          <v-btn
            @click.stop="drawer = !drawer"
          >
            <v-icon>
              {{ icons.mdiFilterVariant }}
            </v-icon>
            Filtres
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-navigation-drawer
          v-model="drawer"
          width="350"
          absolute
          bottom
          temporary
        >
          <v-toolbar
            flat
            color="accent"
            dark
          >
            <v-toolbar-title>Filtrer les taĉhes</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 mb-2">
              Par date
            </h2>

            <v-date-picker
              v-model="dates"
              color="accent"
              locale="fr"
              range
            ></v-date-picker>
          </v-card-text>
          <v-card-text>
            <h2 class="text-h6 mb-2">
              Par utilisateur
            </h2>

            <v-chip-group
              v-model="selectedUsers"
              column
              multiple
            >
              <v-chip
                v-for="user in $store.state.userlist"
                :key="user.id"
                :value="user.id"
                filter
                outlined
              >
                {{ user.displayName }}
              </v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-text>
            <h2 class="text-h6 mb-2">
              Par type de tâche
            </h2>

            <v-chip-group
              v-model="selectedTypes"
              column
              multiple
            >
              <v-chip
                v-for="type in types"
                :key="type.index"
                :value="type"
                filter
                outlined
              >
                {{ type }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-navigation-drawer>
        <tasks-dashboard-vue
          :selected-types="selectedTypes"
          :selected-users="selectedUsers"
        ></tasks-dashboard-vue>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiClose, mdiFilterVariant } from '@mdi/js'
import TasksDashboardVue from './TasksDashboard.vue'

export default {
  components: {
    TasksDashboardVue,
  },
  data() {
    return {
      dialog: false,
      drawer: false,
      selectedTypes: [],
      selectedUsers: [],
      dates: ['2019-09-10', '2019-09-20'],
      types: this.$store.getters.tasksTypes,
      icons: {
        mdiClose,
        mdiFilterVariant,
      },
    }
  },
  mounted() {
    this.selectedUsers = [this.$store.state.user.id]
    this.selectedTypes = this.$store.getters.tasksTypes
  },
  methods: {
  },

}
</script>

<style>

</style>
