import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})
Vue.use(Vuex)

const defaultState = () => ({
  status: '',
  token: localStorage.getItem('token') || '',
  user: {
    avatar: {
      url: null,
    },
    displayName: 'Default',
  },
  company: {},
  snack: {},
  tasks: [],
  userlist: [],
  students: [],
  preferences: {
    info: true, tasks: false, formation: false, visio: true, documents: false, rappel: true, contact: true, history: true, files: false,
  },
})
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {
      avatar: {
        url: null,
      },
      displayName: 'Default',
    },
    company: {},
    snack: {},
    userlist: [],
    students: [],
    preferences: {
      info: true, tasks: false, formation: false, visio: true, documents: false, rappel: true, contact: true, history: true, files: false,
    },
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      if (payload.user) {
        state.user = payload.user
      }
    },
    auth_error(state) {
      localStorage.removeItem('token')
      state.status = 'error'
    },
    logout(state) {
      Object.assign(state, defaultState())
    },
    auth_getInfos(state, payload) {
      state.user = payload
    },
    updatingUserPref(state) {
      state.user.preferences = 'updating...'
    },
    user_preferences(state, payload) {
      state.user.preferences = payload
    },
    user_preferences_error(state) {
      state.user.preferences = 'error'
    },
    students(state, payload) {
      state.students = payload
    },
    student_error(state) {
      state.students = 'error'
    },
    userlist(state, payload) {
      state.userlist = payload.userlist
    },
    companyInfos(state, payload) {
      state.company = payload.company
    },
    snackMe(state, payload) {
      state.snack = payload
    },
    cleanAll(state, payload) {
      state = payload
    },
    studentUpdate(state, payload) {
      const foundIndex = state.students.findIndex(student => student.id === payload.id)
      if (foundIndex) {
        state.students[foundIndex] = payload
      }
    },
    tutorsUpdate(state, payload) {
      const foundIndex = state.user.tutors.findIndex(student => student.id === payload.id)
      if (foundIndex) {
        state.tutors[foundIndex] = payload
      }
    },
    company(state, payload) {
      state.company = payload
    },
    status(state, payload) {
      state.status = payload
    },
  },
  actions: {
    // AUTH - USER //
    getStatus({ commit }) {
      axios.get(`${process.env.VUE_APP_API_URL}/users/my-status`)
        .then(resp => {
          const payload = resp.data
          commit('status', payload)
        })
        .catch(err => {
          if (err.request.status === 403) {
            commit('status', 're-auth')
          }
          const payload = 'error'
          commit('status', payload)
          console.log(err)
        })
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post(`${process.env.VUE_APP_API_URL}/users/login`, user)
          .then(resp => {
            const payload = {
              user: resp.data.user,
              token: resp.data.token,
            }
            if (payload.user.avatar) {
              payload.user.avatar = `${process.env.VUE_APP_API_URL}${payload.user.avatar.url}`
            } else {
              payload.user.avatar = null
            }
            localStorage.setItem('token', payload.token)
            axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`
            commit('auth_success', payload)

            // dispatch('getTasks')
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },
    loginWithCompany({ commit }, company) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post(`${process.env.VUE_APP_API_URL}/users/loginWithCompany`, company)
          .then(resp => {
            const payload = {
              token: resp.data.token,
            }
            localStorage.setItem('token', payload.token)
            axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`
            commit('auth_success', payload)

            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },
    getInfos({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: `${process.env.VUE_APP_API_URL}/users/me`, method: 'get' })
          .then(resp => {
            console.log(resp)
            const payload = resp.data
            commit('auth_getInfos', payload)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
        commit('cleanAll')
        resolve()
        reject()
      })
    },
    updateUserPreferences({ commit }, preferences) {
      return new Promise((resolve, reject) => {
        commit('updatingUserPref')
        axios.post(`${process.env.VUE_APP_API_URL}/users/update-preferences`,
          preferences)
          .then(res => {
            if (res.status === 200) {
              const payload = res.data

              commit('user_preferences', payload)
              resolve(res)
            }
          })
          .catch(err => {
            commit('user_preferences_error')
            reject(err)
          })
      })
    },

    // STUDENTS //
    getStudents({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: `${process.env.VUE_APP_API_URL}/students/light`, method: 'get' })
          .then(resp => {
            const payload = resp.data
            commit('students', payload)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    tutorNoteUpdate({ commit }, newNote) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}/students/${newNote.id}`, { tutorNote: newNote.tutorNote })
          .then(resp => {
            const payload = resp.data
            commit('studentUpdate', payload)
            commit('tutorsUpdate', payload)
            resolve(resp)
          })
          .catch(err => {
            // commit('students_error')
            reject(err)
          })
      })
    },

    // MISC //
    getUserlist({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/company/user-list/`)
          .then(resp => {
            const payload = {
              userlist: resp.data,
            }
            commit('userlist', payload)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getCompanyInfos({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/company/me`)
          .then(resp => {
            const payload = {
              company: resp.data,
            }
            commit('companyInfos', payload)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    cleanAll({ commit }) {
      const payload = {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {
          avatar: {
            url: null,
          },
          displayName: 'Default',
        },
        snack: {},
        tasks: [],
        userlist: [],
        students: [],
      }
      commit('cleanAll', payload)
    },
  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
    isAdmin: state => state.status === 'admin',
    isTuteur: state => state.status === 'tuteur' || state.status === 'admin',
    authStatus: state => state.status,
    userSignature(state) {
      return state.user.emailSignature
    },
    userStatus: state => state.user.status,
    getTutors: state => state.userlist.filter(x => x.status === 'tuteur'),
    myTutors: state => (state.user.status === 'tuteur' ? state.user.tutors : false),
  },
  plugins: [vuexLocal.plugin],
})
