<template>
  <v-icon
    color="primary"
    class="ml-5"
    @click.prevent="$router.go(-1)"
  >
    {{ icons.mdiArrowLeft }}
  </v-icon>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiArrowLeft,
      },
    }
  },
}
</script>

<style>

</style>
