<template>
  <v-btn
    v-show="btt"
    v-scroll="onScroll"
    fab
    dark
    fixed
    bottom
    right
    color="primary"
    @click="toTop"
  >
    <v-icon>{{ icons.mdiChevronUp }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiChevronUp,
      },
      btt: false,
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.btt = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>

<style>

</style>
