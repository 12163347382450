const workingDays = {
  getWorkingDaysBefore: (date, daysBefore) => {
    const ferie = [
      '01-11-2021',
      '11-11-2021',
      '25-12-2021',
      '01-01-2022',
      '18-04-2022',
      '01-05-2022',
      '08-05-2022',
      '26-05-2022',
      '06-06-2022',
      '14-07-2022',
      '15-08-2022',
      '01-11-2022',
      '11-11-2022',
      '25-12-2022',
      '01-01-2023',
      '09-04-2023',
      '01-05-2023',
      '08-05-2023',
      '18-05-2023',
      '28-05-2023',
      '29-05-2023',
      '14-07-2023',
      '15-08-2023',
      '01-11-2023',
      '11-11-2023',
      '25-12-2023',
    ]
    const isFerie = dateToCheck => {
      (ferie).forEach(jourFerie => {
        if (new Date(jourFerie).toISOString === (new Date(dateToCheck).toISOString)) {
          return true
        }

        return false
      })
    }

    const isOuvre = dateToCheck => {
      if (dateToCheck.getDay() === 6 || dateToCheck.getDay() === 0 || isFerie(dateToCheck)) {
        return false
      }

      return true
    }

    let loop = new Date(date)
    let i = 0
    while (i < daysBefore) {
      if (isOuvre(loop)) {
        const newDate = loop.setDate(loop.getDate() - 1)
        loop = new Date(newDate)
        i += 1
      } else {
        const newDate = loop.setDate(loop.getDate() - 1)
        loop = new Date(newDate)
      }
    }
    while (!isOuvre(loop)) {
      const newDate = loop.setDate(loop.getDate() - 1)
      loop = new Date(newDate)
    }

    return loop
  },
}

export default workingDays
