<template>
  <div>
    <v-card
      :ripple="false"
      class="ma-1"
      :class="task.closest ? 'bordered' : ''"
      elevation="2"
      @click.prevent="show =! show"
    >
      <v-card-title class="d-flex justify-space-between">
        <h3>
          {{ new Date(task.date) | date('HH:mm') }}
        </h3>

        <TaskListCardDayButtonVue :date="task.date"></TaskListCardDayButtonVue>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-space-between">
        <TaskListCardStatusChipVue :type="task.type"></TaskListCardStatusChipVue>
        <UserAvatarVue
          size="34px"
          color="primary"
          :user="task.users_permissions_user"
        ></UserAvatarVue>
      </v-card-text>

      <template v-if="task.student">
        <v-card-title class="d-flex justify-space-between">
          <h4>
            {{ task.student.nom }} {{ task.student.prenom }}
          </h4>
          <v-btn
            fab
            small
            outlined
            color="grey"
            @click.prevent="goToStudent(task.student.id)"
          >
            <v-icon>{{ icons.mdiAccountOutline }}</v-icon>
          </v-btn>
        </v-card-title>
      </template>
      <template v-else>
        <v-card-text>
          Note :
          <h4>
            {{ task.note }}
          </h4>
        </v-card-text>
      </template>
      <v-expand-transition>
        <div v-show="show">
          <div v-if="task.student">
            <v-divider></v-divider>
            <v-card-text>
              {{ task.student.tel }}
            </v-card-text>
            <v-card-text>
              {{ task.student.email }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <h4>Note :</h4>
              {{ task.note }}
            </v-card-text>
          </div>
          <v-divider></v-divider>

          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              fab
              color="primary"
              outlined
              class="hover-i"
            >
              <v-icon>
                {{ icons.mdiArrowUp }}
              </v-icon>
            </v-btn>
            <v-btn
              color="success"
              outlined
              fab
              class="hover-i-green"
              @click.prevent="completeTask(task.id)"
            >
              <v-icon>
                {{ icons.mdiCheckOutline }}
              </v-icon>
            </v-btn>

            <v-btn
              fab
              color="error"
              outlined
              class="hover-i-red"
              @click.prevent="deleteTask(task.id)"
            >
              <v-icon>
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import {
  mdiChevronDown, mdiChevronUp, mdiAccountOutline, mdiCheckOutline, mdiDeleteOutline, mdiArrowUp,
} from '@mdi/js'

import UserAvatarVue from './UserAvatar.vue'
import TaskListCardStatusChipVue from './TaskListCardStatusChip.vue'
import TaskListCardDayButtonVue from './TaskListCardDayButton.vue'

export default {
  components: {
    UserAvatarVue,
    TaskListCardStatusChipVue,
    TaskListCardDayButtonVue,
  },
  props: {
    task: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      show: false,
    }
  },

  setup() {
    return {
      icons: {
        mdiChevronDown,
        mdiChevronUp,
        mdiAccountOutline,
        mdiCheckOutline,
        mdiDeleteOutline,
        mdiArrowUp,
      },
    }
  },
  methods: {
    goToStudent(e) {
      this.$router.push(`/student/view/${e}`)
    },
    completeTask(t) {
      this.$store.dispatch('completeTask', { id: t })
    },
    deleteTask(t) {
      this.$store.dispatch('deleteTask', { id: t })
    },
    forwardTask(t) {
      this.$store.dispatch('forwardTask', { id: t, users_permissions_user: this.forwardTo })
      this.forwardDialog = false
    },
  },

}
</script>

<style>
.bordered {
  border: 3px solid #000 !important;
}
</style>
