<template>
  <v-app>
    <v-snackbar
      v-model="snack.value"
      class="text-center"
      :color="snack.color"
      :timeout="5000"
    >
      {{ snack.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snack.value = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      snack: {
        value: false,
        color: 'primary',
        text: 'Bravo',
      },
    }
  },
  computed: {
    snacky() {
      return this.$store.state.snack
    },
  },
  watch: {
    snacky(newsnack) {
      this.snack = newsnack
    },
  },
}
</script>

<style>
</style>
