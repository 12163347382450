<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-avatar
    :left="side ==='left'"
    :right="side === 'right'"
    :size="size"
    :color="color"
    class="white--text"
    v-html="getAvatar"
  >
  </v-avatar>
  <!--eslint-enable-->
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '34px',
    },
    side: {
      type: String,
      default: 'left',
    },
    color: {
      type: String,
      default: 'primary',
    },
    user: {
      type: Object,
      default: () => ({
        displayName: '??',
      }),
    },
  },
  computed: {
    getAvatar() {
      return this.user.avatar ? `<img src="${process.env.VUE_APP_API_URL}${this.user.avatar.url}">` : `${this.user.displayName.charAt(0)}${this.user.displayName.charAt(this.user.displayName.length - 2)}`
    },
  },
}
</script>

<style>

</style>
