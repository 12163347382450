<template>
  <div>
    <v-row>
      <v-col
        v-for="cat in tasksToShow"
        v-if="cat.tasks.length > 0"
        :key="cat.index"
      >
        <TaskListColumnVue
          :tasks="cat.tasks"
          :color="cat.color"
          :title="cat.title"
        ></TaskListColumnVue>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiFilterVariant } from '@mdi/js'
import TaskListColumnVue from '@/components/TaskListColumn.vue'

export default {
  components: {
    TaskListColumnVue,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    selectedTypes: {
      type: Array,
      default: () => ['rappel', 'entree', 'sortie'],
    },
  },
  data() {
    return {
      forwardDialog: false,
      forwardTo: null,
    }
  },
  computed: {
    tasksToShow() {
      const filteredTasks = this.$store.state.tasks.filter(this.myFilter)

      return [{
        type: 'rappel',
        tasks: filteredTasks.filter(task => task.type === 'rappel'),
        color: 'beige',
        title: 'Rappels',
      }, {
        type: 'entree',
        tasks: filteredTasks.filter(task => task.type === 'entree'),
        color: 'teal',
        title: 'Entrées',
      },
      {
        type: 'sortie',
        tasks: filteredTasks.filter(task => task.type === 'sortie'),
        color: 'orange',
        title: 'Sorties',
      },
      {
        type: 'certif',
        tasks: filteredTasks.filter(task => task.type === 'certif'),
        color: 'bleu',
        title: 'Certifications',
      },
      ]
    },
  },
  setup() {
    return {
      icons: {
        mdiFilterVariant,
      },
    }
  },
  mounted() {
    // this.$store.dispatch('getTasks')
    this.$store.dispatch('getUserlist')
  },
  methods: {
    myFilter(task) {
      if (task.users_permissions_user && this.selectedUsers.includes(task.users_permissions_user.id)) {
        return this.selectedTypes.includes(task.type)
      }

      return false
    },

  },
}
</script>

<style>

</style>
