<template>
  <v-chip
    color="primary"
    outlined
  >
    <v-icon
      size="20"
      left
    >
      {{ typeRender.icon }}
    </v-icon>
    {{ typeRender.texte }}
  </v-chip>
</template>

<script>
import {
  mdiChatOutline, mdiAccountArrowRight, mdiAccountPlus, mdiCertificate, mdiDraw, mdiBellAlert,
} from '@mdi/js'

export default {
  props: {
    type: {
      type: String,
      default: 'rappel',
    },
  },
  computed: {
    typeRender() {
      switch (this.type) {
        case 'rappel':
          return {
            texte: 'Rappel',
            icon: this.icons.mdiChatOutline,
          }
        case 'sortie':
          return {
            texte: 'Sortie',
            icon: this.icons.mdiAccountArrowRight,
          }
        case 'entree':
          return {
            texte: 'Entrée',
            icon: this.icons.mdiAccountPlus,
          }
        case 'certif':
          return {
            texte: 'Certification',
            icon: this.icons.mdiCertificate,
          }
        case 'signature':
          return {
            texte: 'Signature',
            icon: this.icons.mdiDraw,
          }
        default:
          return {
            texte: 'Autre',
            icon: this.icons.mdiBellAlert,
          }
      }
    },
  },
  setup() {
    return {
      icons: {
        mdiChatOutline,
        mdiAccountArrowRight,
        mdiAccountPlus,
        mdiCertificate,
        mdiDraw,
        mdiBellAlert,
      },
    }
  },
}
</script>

<style>

</style>
