<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <div
          v-bind="attrs"
          v-on="on"
        >
          <UserAvatarVue
            size="40px"
            color="primary"
            :user="$store.state.user"
          ></UserAvatarVue>
        </div>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <UserAvatarVue
            size="40px"
            color="primary"
            :user="$store.state.user"
          ></UserAvatarVue>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $store.state.user.displayName }}
          </span>
          <small class="text--disabled text-capitalize">{{ $store.state.user.status }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Company -->
      <v-list-item
        link
        @click="companySwap"
      >
        <v-list-item-icon
          class="me-2"
        >
          <v-icon size="22">
            {{ icons.mdiBriefcase }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex justify-space-between">
              <span>
                {{ $store.state.company.name }}
              </span>
              <v-icon size="16">
                {{ icons.mdiSwapHorizontal }}
              </v-icon>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item
        link
        @click="profil"
      >
        <v-list-item-icon
          class="me-2"
        >
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mon profil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Logout -->
      <v-list-item
        link
        @click="logout"
      >
        <v-list-item-icon
          class="me-2"
        >
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Se déconnecter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiBriefcase,
  mdiSwapHorizontal,
} from '@mdi/js'

import UserAvatarVue from '@/components/UserAvatar.vue'

export default {
  components: {
    UserAvatarVue,
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiBriefcase,
        mdiSwapHorizontal,
      },
    }
  },
  created() {
    if ((this.$store.state.token && (Object.keys(this.$store.state.user).length === 0)) || this.$store.state.user.displayName === 'Default') {
      this.$store.dispatch('getInfos', this.$store.state.token).then()
        .catch(err => {
          this.logError = true
          console.log(err)
        })
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
        .catch(err => console.log(err))
    },
    profil() {
      this.$router.push({ name: 'user-account-settings' })
    },
    companySwap() {

    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
