<template>
  <v-card
    class="pa-1 rouded-0"
    :color="color"
    tile
  >
    <v-card-title>
      <p class="text-h5">
        {{ title }} ({{ tasks.length }})
      </p>
    </v-card-title>
    <div
      v-for="task in tasks"
      :key="task.index"
    >
      <TaskListCardVue
        :task="task"
      ></TaskListCardVue>
    </div>
  </v-card>
</template>

<script>
import TaskListCardVue from './TaskListCard.vue'

export default {
  components: {
    TaskListCardVue,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: 'Rappels',
    },
  },
}
</script>

<style>

</style>
