require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        bleu: '#1A237E',
        vert: '#8BC34A',
        teal: '#81C784',
        orange: '#FFA726',
        beige: '#FFECB3',
        waiting: '#B0BEC5',
        step1: '#FB8C00',
        step2: '#FFEB3B',
        step3: '#C0CA33',
      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        bleu: '#1A237E',
        vert: '#8BC34A',
        teal: '#81C784',
        orange: '#FFA726',
        beige: '#FFECB3',
        waiting: '#B0BEC5',
        step1: '#FB8C00',
        step2: '#FFEB3B',
        step3: '#C0CA33',
      },
    },
  },
}
