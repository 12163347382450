<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        class="ml-5"
        color="primary"
        v-on="on"
      >
        {{ icons.mdiMagnify }}
      </v-icon>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-form>
          <v-card-text class="pt-3">
            <v-text-field
              v-model="search"
              label="Recherche"
              :autofocus="true"
              clearable
              @click:clear="results= []"
            ></v-text-field>
          </v-card-text>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-two-line, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
          <v-card-text v-else-if="noresults">
            Pas de résultats :(
          </v-card-text>
          <v-card-text v-else>
            <v-list rounded>
              <v-subheader v-if="results.length !== 0">
                APPRENANTS
              </v-subheader>
              <v-list-item-group
                v-model="selectedResult"
                color="primary"
              >
                <v-list-item
                  v-for="(result, i) in results"
                  :key="i"
                  two-line
                >
                  <v-list-item-content @click.prevent="$router.push({ name: 'student-single', params: { id: result.id } })">
                    <v-list-item-title v-html="highlighted(result, search) ">
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ result.formation.nom }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="!result.rappeled">
                      <i>
                        Rappel le {{ new Date(result.rappel) | date('dd MMMM yyyy à HH:mm') }}</i>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog.value = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  data() {
    return {
      selectedResult: '',
      search: '',
      noresults: false,
      loading: false,
      results: [],
      icons: {
        mdiMagnify,
      },
    }
  },
  watch: {
    search(e) {
      this.searchStudent(e)
    },
  },
  mounted() {
    this.results = []
  },
  methods: {
    highlighted(result, query) {
      let fullName = `${result.nom} ${result.prenom}`
      const reg = new RegExp(query, 'ig')
      fullName = fullName.replace(reg, `<b>${query}</b>`).toUpperCase()

      return fullName
    },
    searchStudent(query) {
      if (query && query.length > 2) {
        this.results = []
        this.loading = true
        this.$http.get(`${process.env.VUE_APP_API_URL}/students?_where[_or][0][nom_contains]=${query}&_where[_or][1][prenom_contains]=${query}`)
          .then(response => {
            this.noresults = response.data.length === 0
            this.results = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.log(error)
          })
          .finally(
            this.loading = false,
          )
      }
    },
  },
}
</script>

<style>

</style>
