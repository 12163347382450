<template>
  <div>
    <v-chip
      v-if="niceDate(date) ==='Aujourd\'hui'"
      color="primary"
    >
      {{ niceDate(date) }}
    </v-chip>
    <v-chip
      v-else-if="niceDate(date) ==='Demain'"
      color="accent"
    >
      {{ niceDate(date) }}
    </v-chip>
    <v-chip
      v-else-if="niceDate(date) ==='Hier'"
      color="error"
    >
      {{ niceDate(date) }}
    </v-chip>
    <v-chip
      v-else
      color="primary"
      outlined
    >
      {{ niceDate(date) }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default: () => new Date(),
    },
  },
  methods: {
    niceDate(date) {
      const now = new Date().setHours(0, 0, 0, 0)

      if (new Date(date).setHours(0, 0, 0, 0) === now) {
        return 'Aujourd\'hui'
      } if (new Date(date).setHours(0, 0, 0, 0) === new Date(now).setDate(new Date(now).getDate() + 1)) {
        return 'Demain'
      } if (new Date(date).setHours(0, 0, 0, 0) === new Date(now).setDate(new Date(now).getDate() + -1)) {
        return 'Hier'
      }

      return this.$date(new Date(date), 'dd/MM/yyyy')
    },
  },

}
</script>

<style>

</style>
